import { Link } from 'gatsby';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import styled, { css } from 'styled-components';
import AniLink from 'gatsby-plugin-transition-link/AniLink';
import Logo from './shared/logo';
import { Button, ContactImage } from './shared';
import { WrapMax } from './shared/wraps';
import { Color, ScalingSize, DynamicPadding } from '../config/variables';
import Image from './Image';

interface CtaLink {
  Title: string;
  URL: string;
}

interface Item {
  Image?: ItemImage;
  Heading: string;
  Text: string;
  Link: CtaLink;
}

interface ItemImage {
  alternativeText: string;
  url: string;
  imageFile: { relativePath: string };
}

interface Props {
  items?: Item[];
  item?: Item;
}

export default class Cta extends Component<Props> {
  constructor(props: Props) {
    super(props);
  }

  render() {
    const { item, items } = this.props;

    return (
      <Outer single={item === null ? 0 : 1}>
        <Inner>
          {items && items.length && items.map((item, index) => this.renderItem(item, index))}
          {item && this.renderItem(item, 0, true)}
        </Inner>
      </Outer>
    );
  }

  renderItem(item: Item, index: number, single?: boolean) {
    return (
      <AniLink to={item.Link.URL} bg={Color.citrus5} cover direction="left" key={item.Link.URL}>
      <Item key={`ctaItem_${index}`} single={single ? 1 : 0}>
        {item.Image && (
          <ImageWrap>
            <Image filename={item.Image.imageFile.relativePath} alt={item.Image.alternativeText} />
          </ImageWrap>
        )}

        <Title>{item.Heading}</Title>

        <Content single={single ? 1 : 0}>
          <Paragraph>{item.Text}</Paragraph>
          {item.Link && <Button theme="ghost" icon="ArrowRight" text={item.Link.Title} />}
        </Content>
      </Item>
      </AniLink>
    );
  }
}

const Outer = styled.footer`
  ${DynamicPadding}
  background-color: ${Color.champ4};
  ${props => props.single && ScalingSize('margin-top', 24, 40)};
  ${props => !props.single && ScalingSize('margin-top', 2, 20)};
`;

const Inner = styled(WrapMax)`
  display: grid;
  grid-gap: 3.5em;
  // grid-template-columns: repeat(auto-fit, minmax(18.75em, 1fr));

  @media screen and (min-width: 640px) {
    grid-template-columns: repeat(auto-fit, minmax(31.875em, 1fr));
    grid-gap: 6.111111111vw;
  }
`;

const Item = styled.div`
  color: ${Color.slate};
  display: grid;
  min-height: 100px;
  grid-template-areas:
    'title image'
    'content content';
  grid-template-columns: auto 4em;
  grid-gap: 1.5em;
  align-items: center;

  @media screen and (min-width: 640px) {
    grid-template-areas:
      'image .'
      'image title'
      'image content'
      'image .';
    grid-template-columns: ${props => (props.single ? '8.888888889vw' : '12.777777778vw')} 356fr;
    grid-gap: 0 2.222222222vw;
  }
  @media screen and (min-width: 1680px) {
    grid-template-columns: ${props => (props.single ? '9.33em' : '13.42em')} 356fr;
    grid-gap: 0 2.333333333em;
  }
`;

const ImageWrap = styled.div`
  width: 4em;
  height: 4em;
  grid-area: image;

  @media screen and (min-width: 640px) {
    grid-row: image-start / image-end;
    width: 100%;
    height: 0;
    padding-bottom: 100%;
  }

  img {
    width: 100%;
    border-radius: 90em;
  }
`;

const Content = styled.div`
  grid-column: content-start / content-end;
  grid-area: content;
  ${props =>
    props.single &&
    `
    max-width: 68.076923077%;
  `};
`;

const Title = styled.h5`
  font-size: 1.5em;
  line-height: 2rem;
  letter-spacing: -0.02em;
  margin: 0;
  grid-area: title;

  @media screen and (min-width: 640px) {
    font-size: 1.25em;
    margin-bottom: 0.5rem;
  }
`;

const Paragraph = styled.p`
  font-size: 0.875em;
  line-height: 1.5rem;
  color: ${Color.slate3};
  margin: 0 0 1.5rem;
`;
