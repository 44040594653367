import React, { Component } from 'react';
import styled from 'styled-components';

import { BlockCoworkersBlock } from 'src/shared/interfaces';
import { Color, FontSans, FontSerif, ScalingSize } from '../../config/variables';
import { ContactImage } from '../shared';

interface Props {
  data: BlockCoworkersBlock;
}

export default class ContactCard extends Component<Props> {
  render() {
    const { data } = this.props;

    return (
      <Card>
        <ContactImage image={{ ...data.coworker.Image, color: data.BackgroundColor }} />
        {data.coworker.Name.length > 0 && (
          <Content>
            <Title>{data.coworker.Name}</Title>
            <Subline>{data.coworker.Role}</Subline>
            {(data.coworker.Phone || data.coworker.Email) && (
              <Meta>
                {data.coworker.Phone && (
                  <MetaItem>
                    <ContactString href={`tel:${data.coworker.Phone}`}>{data.coworker.Phone}</ContactString>
                  </MetaItem>
                )}
                {data.coworker.Email && (
                  <MetaItem>
                    <ContactString href={`mailto:${data.coworker.Email}`}>{data.coworker.Email}</ContactString>
                  </MetaItem>
                )}
              </Meta>
            )}
          </Content>
        )}
      </Card>
    );
  }
}

// Will be used in the future
// const Card = styled(Link)`
const Card = styled.div`
  color: ${Color.slate};
  text-align: center;
  display: flex;
  align-content: flex-start;
  flex-wrap: wrap;
  width: 100%;
`;

const Title = styled.h3`
  margin: 0.75em 0 0;
  ${ScalingSize('font-size', 11, 18)};
`;

const Subline = styled.span`
  color: ${Color.slate3};
  ${FontSans};
  font-size: 0.6875em;
  line-height: 1rem;
  letter-spacing: 0;
  text-transform: uppercase;
  font-weight: bold;

  @media screen and (min-width: 978px) {
    letter-spacing: 0.04em;
    line-height: 1.5rem;
  }
`;

const Meta = styled.ul`
  font-size: 0.75em;
  color: ${Color.slate};
  ${FontSerif};
  margin: 0.25rem 0;
  padding: 0;
`;

const MetaItem = styled.li`
  display: inline-block;

  &:after {
    content: '·';
    margin: 0 0.25em;
  }

  &:last-child:after {
    display: none;
  }
`;

const ContactString = styled.a`
  color: ${Color.slate};

  &:hover,
  &:focus {
    text-decoration: underline;
  }
`;

const Content = styled.div`
  width: 100%;
`;
