import React, { Component, createRef } from "react";
import styled from 'styled-components';
import lottie from 'lottie-web';

import { Color, FontOsynlig, FontSans, ScalingSize, ElevationSoft } from '../../config/variables'
import { WrapMax } from './wraps'
import { scroller } from '../../lottie-files'

import parser from 'html-react-parser';
interface Excerpt {
  title: string;
  content: string;
  tags: string[];
}

interface Props {
  data: Excerpt
}

export default class CaseExcerpt extends Component<Props> {
  private scrollerContainer = createRef<HTMLDivElement>();
  private animation: any;
  constructor(props: Props) {
    super(props);
  }

  componentDidMount () {
      this.animation = lottie.loadAnimation({
        container: this.scrollerContainer.current,
        renderer: "svg",
        loop: true,
        autoplay: true,
        animationData: scroller,
      });
  }

  componentWillUnmount () {
    this.animation.destroy();
  }

  render() {
    const {
      data
    } = this.props;  
    
    return (
      <ExcerptWrap>
        <Scroller>
          <ScrollerInner ref={this.scrollerContainer} />
        </Scroller>
        <Title>{data.title}</Title>
        <Content>
          {parser(data.content)}
        </Content>
        <Tags>
          {data.tags.map((tag, i) => (
            <Tag key={i}>{tag}</Tag>
          ))}
        </Tags>
      </ExcerptWrap>
    )
  }
}

const ExcerptWrap = styled(WrapMax)`
  display: grid;
  grid-gap: 1em 3.5em;
  ${ScalingSize('margin-top', 16, 144)}
  ${ScalingSize('margin-bottom', 32, 112)}
  
  @media screen and (min-width: 680px) {
    grid-template-columns: auto 8em;
  }

  @media screen and (min-width: 769px) {
    grid-template-columns: 8em auto 8em;
  }
`;

const Scroller = styled.div`
  position: relative;
  grid-column: 1;
  grid-row: 1 / 3;
  display: none;
  align-self: center;
  padding-bottom: 100%;
  border-radius: 99em;
  background-color: ${Color.white};
  ${ElevationSoft};

  @media screen and (min-width: 769px) {
    display: block;
  }
`;

const ScrollerInner = styled.div`
  position: absolute;
  width: 2em;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
`;

const Content = styled.div`

  @media screen and (min-width: 680px) {
    column-gap: 1.5em;
    grid-row: 2;
    columns: 2 366px;
    grid-column: 1;
    margin-right: 2.5em;
  }

  @media screen and (min-width: 769px) {
    grid-column: 2;
  }

  p {
    margin: 0 0 1.5em;
    line-height: 1.75;
    color: ${Color.slate3};
  }
`;

const Title = styled.span`
  display: flex;
  ${FontOsynlig};
  font-size: 1.5em;
  line-height: 2rem;
  position: relative;

  @media screen and (min-width: 680px) {
    grid-row: 1;
    grid-column: 1 / 3;
  }

  @media screen and (min-width: 769px) {
    grid-column: 2 / 4;
  }

  &:after {
    grid-row: 2;
    margin-left: auto;
    color: ${Color.citrus};
    content: '—';
    display: none;

    @media screen and (min-width: 680px) {
      display: block;
    }
  }
`;

const Tags = styled.ul`
  grid-row: 1;
  list-style: none;
  margin: 0;
  padding: 0;

  @media screen and (min-width: 680px) {
    grid-row: 2;
    text-align: right;
  }
  
  &:before {
    ${FontOsynlig};
    font-size: 1.5em;
    line-height: 2rem;
    color: ${Color.citrus};
    content: '—';
    display: block;
    margin-bottom: .5rem;

    @media screen and (min-width: 680px) {
      display: none;
    }
  }
`;

const Tag = styled.li`
  font-size: .6875em;
  line-height: 2em;
  ${FontSans}
  margin-bottom: .75rem;
  text-transform: uppercase;
  font-weight: bold;
  display: inline-flex;

  @media screen and (min-width: 680px) {
    display: block;
  }

  &:after {
    content: '·';
    margin: 0 0.75em;

    @media screen and (min-width: 680px) {
      display: none;
    }
  }

  &:last-child:after {
    display: none;
  }
`;