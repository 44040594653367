import React, { Component } from 'react';
import styled, { css } from 'styled-components';
import Fade from 'react-reveal/Fade';

import { BlockCaseEnd as CaseEndType } from 'src/shared/interfaces';

import { WrapXs} from 'src/components/shared/wraps';
import { ContactImage, Button } from 'src/components/shared';
import { Color, FontOsynlig } from '../config/variables';
import { Mail, Smartphone } from 'src/components/shared/Icon';

interface Props {
  block: CaseEndType;
}

export class BlockCaseEnd extends Component<Props> {

  constructor(props: Props) {
    super(props);
  }

  render() {
    const { block } = this.props;

    return (
      <WrapXs>
        <Outer>
          <Inner>
            <Item key={`coworker`}>
              <ImageWrap>
                <ContactImage align image={{ ...block.Coworker.Image, ...Color.cloud3 }} /> 
              </ImageWrap>
              <Title>{'Vill du veta mer?'}</Title>
              <Content>
                <Paragraph>{'Prata med'} {block.Coworker.Name}, {block.Coworker.Role}</Paragraph>
              </Content>
              <Contact>
                {block.Coworker.Phone && (
                <ContactItem>
                  <StyledSmartphone />
                  <ContactString href={`tel:${block.Coworker.Phone}`}>{block.Coworker.Phone}</ContactString>
                </ContactItem>
                )}
                {block.Coworker.Email && (
                <ContactItem>
                  <StyledMail />
                  <ContactString href={`mailto:${block.Coworker.Email}`}>{block.Coworker.Email}</ContactString>
                </ContactItem>
                )}
              </Contact>
            </Item>
          </Inner>
        </Outer>
      </WrapXs>
    );
  }
}

const Buttons = styled.div`
  button,
  a {
    margin-bottom: 1.25rem;
  }
`;

const Contact = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
  grid-area: contact;
`;

const ContactItem = styled.li`
  display: inline-flex;
  align-content: center;
  white-space: nowrap;
  margin-right: 2em;
  margin-bottom: 1em;
`;

const ContactString = styled.a`
  ${FontOsynlig};
  line-height: 1.25rem;
  flex: 1 1 auto;
  color: ${Color.slate};

  &:hover,
  &:focus {
    text-decoration: underline;
  }
`;

const StyledIcon = css`
  fill: transparent;
  stroke: currentColor;
  width: 1.25em;
  height: 1.25em;
  flex: 0 0 1.25em;
  margin-right: 0.625em;
  stroke-width: 1.5;
  stroke-linecap: round;
  stroke-linejoin: round;
`;

const StyledMail = styled(Mail)`
  ${StyledIcon};
`;

const StyledSmartphone = styled(Smartphone)`
  ${StyledIcon};
`;

const Outer = styled.footer`
  padding: 2.625em 0 1.5em;
  border-top: 1px solid ${Color.cloud};
  ScalingSize('margin-top', 24, 40);
  margin-top: 2.625em;
  margin-bottom: 5em;
`;

const Inner = styled.div`
  display: grid;
  grid-gap: 3.5em;
  // grid-template-columns: repeat(auto-fit, minmax(18.75em, 1fr));

  @media screen and (min-width: 640px) {
    grid-template-columns: repeat(auto-fit, minmax(31.875em, 1fr));
    grid-gap: 6.111111111vw;
  }
`;

const Item = styled.div`
  color: ${Color.slate};
  display: grid;
  min-height: 100px;
  grid-template-areas:
    'title title title'
    'image content content'
    'image contact contact';
  grid-template-columns: 20vw auto auto;
  grid-gap: 0 1.5em;
  align-items: center;

  @media screen and (min-width: 640px) {
    grid-template-areas:
      'image .'
      'image title'
      'image content'
      'image contact'
      'image .';
    grid-template-columns: 8em 356fr;
    grid-gap: 0 2rem;
  }
`;

const ImageWrap = styled.div`
  width: 20vw;
  height: 20vw;
  grid-area: image;

  @media screen and (min-width: 640px) {
    grid-row: image-start / image-end;
    width: 100%;
    height: 0;
    padding-bottom: 100%;
  }

  img {
    width: 100%;
    border-radius: 90em;
  }
`;

const Content = styled.div`
  grid-column: content-start / content-end;
  grid-area: content;

  @media screen and (min-width: 640px) {
    max-width: 68.076923077%;
  }
`;

const Title = styled.h5`
  font-size: 1.5em;
  line-height: 2rem;
  letter-spacing: -0.02em;
  margin: 0;
  grid-area: title;
  margin-bottom: 1em;

  @media screen and (min-width: 640px) {
    font-size: 1.25em;
    margin-bottom: 0.5rem;
  }
`;

const Paragraph = styled.p`
  font-size: 0.875em;
  line-height: 1.5rem;
  color: ${Color.slate3};
  margin: 0 0 1.125rem;
`;
