import { withCookies } from 'react-cookie';
import React, { Component, createRef } from 'react';
import styled from 'styled-components';
import AniLink from 'gatsby-plugin-transition-link/AniLink';

import { Button, External } from './shared';
import { Wrap } from './shared/wraps';
import { Color } from '../config/variables';

interface State {
  render: Boolean;
  height: number;
  stopTransition: Boolean;
}

const COOKIE_NAME = 'osynlig-allow-cookies';

class CookieWarning extends Component<any, State> {
  private transitionRef = createRef<HTMLDivElement>();
  private heightRef = createRef<HTMLDivElement>();

  constructor(props) {
    super(props);

    this.state = {
      render: true,
      height: 0,
      stopTransition: false,
    };
  }

  shouldRender() {
    const { render } = this.state;

    return render;
  }

  componentDidMount() {
    const { cookies } = this.props;

    if (cookies.get(COOKIE_NAME)) {
      this.setState({
        render: false,
      });
    } else {
      const el = this.heightRef.current;

      setTimeout(() => {
        const height = el.clientHeight;
        this.setState({
          height,
        });
      }, 250);

      this.addListener();
    }
  }

  componentWillUnmount() {
    this.removeListener();
  }

  render() {
    const { render, height, stopTransition } = this.state;

    return (
      render && (
        <FadeWrap className={`${stopTransition ? 'notransition' : ''}`} ref={this.transitionRef} style={{ height: `${height}px` }}>
          <Outer ref={this.heightRef}>
            <Inner>
              <Paragraph>
                Vi använder cookies för mät- och analysändamål samt marknadsföring.{' '}
                <StyledLink bg={Color.citrus5} cover direction="left" to="/integritetspolicy">
                  Läs mer i vår cookiepolicy
                </StyledLink>
                .
              </Paragraph>
              <Button extraStyle={{ whiteSpace: 'nowrap' }} theme="secondary" size="small" onClick={this.onAccept.bind(this)} text="Jag godkänner" />
            </Inner>
          </Outer>
        </FadeWrap>
      )
    );
  }

  addListener() {
    this.transitionRef.current.addEventListener('transitionend', this.onTransitionEnd.bind(this), false);
    window.addEventListener('resize', this.onResize.bind(this), false);
  }

  removeListener() {
    this.transitionRef.current.removeEventListener('transitionend', this.onTransitionEnd.bind(this), false);
    window.removeEventListener('resize', this.onResize.bind(this), false);
  }

  onAccept() {
    const { cookies } = this.props;

    // Cookie saved for 3 months
    const date = new Date();
    date.setMonth(date.getMonth() + 3);

    cookies.set(COOKIE_NAME, true, { path: '/', expires: date, domain: '.osynlig.se' });

    this.setState({
      height: 0,
    });
  }

  onResize() {
    const { render, height } = this.state;

    if (render) {
      const el = this.heightRef.current;
      const parent = el.parentNode;
      const currentHeight = el.clientHeight;

      if (height !== currentHeight) {
        this.setState(
          {
            stopTransition: true,
          },
          () => {
            this.setState(
              {
                height: currentHeight,
              },
              () => {
                setTimeout(() => {
                  this.setState({
                    stopTransition: false,
                  });
                });
              }
            );
          }
        );
      }
    }
  }

  onTransitionEnd() {
    if (this.state.height === 0) {
      this.removeListener();
      this.setState({
        render: false,
      });
    }
  }
}

export default withCookies(CookieWarning);

const TIMING = 400;
const FadeWrap = styled.div`
  order: -40;
  overflow: hidden;
  transition: height ${TIMING}ms cubic-bezier(0, 0.32, 0.4, 1);

  &.notransition {
    transition: none;
  }
`;

const Outer = styled.div`
  width: 100vw;
  background-color: ${Color.champ4};
  color: ${Color.slate};
  padding: 1.25rem 0;
  font-size: 0.875em;
`;

const Inner = styled(Wrap)`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Paragraph = styled.p`
  line-height: 1.5; // REMOVE ME
  margin: 0 1.5rem 0 0;
`;

const StyledLink = styled(AniLink)`
  color: ${Color.slate};
  border-bottom: 1px solid ${Color.citrus};
  transition: border 0.3s ease;

  &:hover,
  &:focus {
    border-color: ${Color.slate};
  }
`;
