import React, { Component, Fragment } from 'react';
import styled from 'styled-components';
import Fade from 'react-reveal/Fade';

import { BlockCoworkersBlock as BlockCoworkersBlockType } from '../shared/interfaces';
import { ContactCard } from 'src/components/shared';
import { WrapLarge } from 'src/components/shared/wraps';

interface Props {
  block: BlockCoworkersBlockType;
}

export const BlockCoworkersBlock = (props: Props) => (
  <Fragment>
    <Fade bottom distance={'10%'} fraction={0.2}>
      <Title>{props.block.Heading}</Title>
    </Fade>

    <Contacts data-color="#fff">
      {props.block.Coworkers.map((coworker, index) => {
        return (
          <ContactCard data={coworker} /> 
        );
      })}
    </Contacts>
  </Fragment>
);

const Title = styled.h2`
  font-size: 2em;
  text-align: center;
  margin: 0 0 3rem;
`;

const Contacts = styled(WrapLarge)`
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  grid-gap: 3.611111111vw 7.222222222vw;
  margin-bottom: 3.611111111vw;

  @media screen and (min-width: 978px) {
    grid-template-columns: repeat(3, 1fr);
  }
`;
