module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-transition-link/gatsby-browser.js'),
      options: {"plugins":[],"layout":"/var/www/preview.osynlig.se/src/components/layout.tsx"},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Osynlig","short_name":"Osynlig","start_url":"/","background_color":"#fff","theme_color":"#ECE5D4","display":"minimal-ui","icon":"src/static/images/favicon.svg","cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"dcc6c504d0fcbab49289d9e213786a70"},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-gdpr-cookies/gatsby-browser.js'),
      options: {"plugins":[],"googleAnalytics":{"trackingId":"UA-25684877-1","cookieName":"osynlig-allow-cookies","anonymize":true},"googleTagManager":{"trackingId":"GTM-WZ3MMZ2","cookieName":"osynlig-allow-cookies","dataLayerName":"dataLayer"},"facebookPixel":{"pixelId":"","cookieName":"osynlig-allow-cookies"},"environments":["production"]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
