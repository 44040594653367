const { hot } = require("react-hot-loader/root")

// prefer default export if available
const preferDefault = m => (m && m.default) || m


exports.components = {
  "component---cache-dev-404-page-js": hot(preferDefault(require("/var/www/preview.osynlig.se/.cache/dev-404-page.js"))),
  "component---src-components-block-tsx": hot(preferDefault(require("/var/www/preview.osynlig.se/src/components/block.tsx"))),
  "component---src-pages-404-tsx": hot(preferDefault(require("/var/www/preview.osynlig.se/src/pages/404.tsx")))
}

