import Activity from '../../static/images/icons/activity.svg';
import Airplay from '../../static/images/icons/airplay.svg';
import AlertCircle from '../../static/images/icons/alert-circle.svg';
import AlertOctagon from '../../static/images/icons/alert-octagon.svg';
import AlertTriangle from '../../static/images/icons/alert-triangle.svg';
import AlignCenter from '../../static/images/icons/align-center.svg';
import AlignJustify from '../../static/images/icons/align-justify.svg';
import AlignLeft from '../../static/images/icons/align-left.svg';
import AlignRight from '../../static/images/icons/align-right.svg';
import Anchor from '../../static/images/icons/anchor.svg';
import Aperture from '../../static/images/icons/aperture.svg';
import Archive from '../../static/images/icons/archive.svg';
import ArrowDownCircle from '../../static/images/icons/arrow-down-circle.svg';
import ArrowDownLeft from '../../static/images/icons/arrow-down-left.svg';
import ArrowDownRight from '../../static/images/icons/arrow-down-right.svg';
import ArrowDown from '../../static/images/icons/arrow-down.svg';
import ArrowLeftCircle from '../../static/images/icons/arrow-left-circle.svg';
import ArrowLeft from '../../static/images/icons/arrow-left.svg';
import ArrowRightCircle from '../../static/images/icons/arrow-right-circle.svg';
import ArrowRight from '../../static/images/icons/arrow-right.svg';
import ArrowUpCircle from '../../static/images/icons/arrow-up-circle.svg';
import ArrowUpLeft from '../../static/images/icons/arrow-up-left.svg';
import ArrowUpRight from '../../static/images/icons/arrow-up-right.svg';
import ArrowUp from '../../static/images/icons/arrow-up.svg';
import AtSign from '../../static/images/icons/at-sign.svg';
import Award from '../../static/images/icons/award.svg';
import BarChart2 from '../../static/images/icons/bar-chart-2.svg';
import BarChart from '../../static/images/icons/bar-chart.svg';
import BatteryCharging from '../../static/images/icons/battery-charging.svg';
import Battery from '../../static/images/icons/battery.svg';
import BellOff from '../../static/images/icons/bell-off.svg';
import Bell from '../../static/images/icons/bell.svg';
import Bluetooth from '../../static/images/icons/bluetooth.svg';
import Bold from '../../static/images/icons/bold.svg';
import BookOpen from '../../static/images/icons/book-open.svg';
import Book from '../../static/images/icons/book.svg';
import Bookmark from '../../static/images/icons/bookmark.svg';
import Box from '../../static/images/icons/box.svg';
import Briefcase from '../../static/images/icons/briefcase.svg';
import Calendar from '../../static/images/icons/calendar.svg';
import CameraOff from '../../static/images/icons/camera-off.svg';
import Camera from '../../static/images/icons/camera.svg';
import Cast from '../../static/images/icons/cast.svg';
import CheckCircle from '../../static/images/icons/check-circle.svg';
import CheckSquare from '../../static/images/icons/check-square.svg';
import Check from '../../static/images/icons/check.svg';
import ChevronDown from '../../static/images/icons/chevron-down.svg';
import ChevronLeft from '../../static/images/icons/chevron-left.svg';
import ChevronRight from '../../static/images/icons/chevron-right.svg';
import ChevronUp from '../../static/images/icons/chevron-up.svg';
import ChevronsDown from '../../static/images/icons/chevrons-down.svg';
import ChevronsLeft from '../../static/images/icons/chevrons-left.svg';
import ChevronsRight from '../../static/images/icons/chevrons-right.svg';
import ChevronsUp from '../../static/images/icons/chevrons-up.svg';
import Chrome from '../../static/images/icons/chrome.svg';
import Circle from '../../static/images/icons/circle.svg';
import Clipboard from '../../static/images/icons/clipboard.svg';
import Clock from '../../static/images/icons/clock.svg';
import CloudDrizzle from '../../static/images/icons/cloud-drizzle.svg';
import CloudLightning from '../../static/images/icons/cloud-lightning.svg';
import CloudOff from '../../static/images/icons/cloud-off.svg';
import CloudRain from '../../static/images/icons/cloud-rain.svg';
import CloudSnow from '../../static/images/icons/cloud-snow.svg';
import Cloud from '../../static/images/icons/cloud.svg';
import Code from '../../static/images/icons/code.svg';
import Codepen from '../../static/images/icons/codepen.svg';
import Codesandbox from '../../static/images/icons/codesandbox.svg';
import Coffee from '../../static/images/icons/coffee.svg';
import Columns from '../../static/images/icons/columns.svg';
import Command from '../../static/images/icons/command.svg';
import Compass from '../../static/images/icons/compass.svg';
import Copy from '../../static/images/icons/copy.svg';
import CornerDownLeft from '../../static/images/icons/corner-down-left.svg';
import CornerDownRight from '../../static/images/icons/corner-down-right.svg';
import CornerLeftDown from '../../static/images/icons/corner-left-down.svg';
import CornerLeftUp from '../../static/images/icons/corner-left-up.svg';
import CornerRightDown from '../../static/images/icons/corner-right-down.svg';
import CornerRightUp from '../../static/images/icons/corner-right-up.svg';
import CornerUpLeft from '../../static/images/icons/corner-up-left.svg';
import CornerUpRight from '../../static/images/icons/corner-up-right.svg';
import Cpu from '../../static/images/icons/cpu.svg';
import CreditCard from '../../static/images/icons/credit-card.svg';
import Crop from '../../static/images/icons/crop.svg';
import Crosshair from '../../static/images/icons/crosshair.svg';
import Database from '../../static/images/icons/database.svg';
import Delete from '../../static/images/icons/delete.svg';
import Disc from '../../static/images/icons/disc.svg';
import DollarSign from '../../static/images/icons/dollar-sign.svg';
import DownloadCloud from '../../static/images/icons/download-cloud.svg';
import Download from '../../static/images/icons/download.svg';
import Droplet from '../../static/images/icons/droplet.svg';
import Edit2 from '../../static/images/icons/edit-2.svg';
import Edit3 from '../../static/images/icons/edit-3.svg';
import Edit from '../../static/images/icons/edit.svg';
import ExternalLink from '../../static/images/icons/external-link.svg';
import EyeOff from '../../static/images/icons/eye-off.svg';
import Eye from '../../static/images/icons/eye.svg';
import Facebook from '../../static/images/icons/facebook.svg';
import FastForward from '../../static/images/icons/fast-forward.svg';
import Feather from '../../static/images/icons/feather.svg';
import Figma from '../../static/images/icons/figma.svg';
import FileMinus from '../../static/images/icons/file-minus.svg';
import FilePlus from '../../static/images/icons/file-plus.svg';
import FileText from '../../static/images/icons/file-text.svg';
import File from '../../static/images/icons/file.svg';
import Film from '../../static/images/icons/film.svg';
import Filter from '../../static/images/icons/filter.svg';
import Flag from '../../static/images/icons/flag.svg';
import FolderMinus from '../../static/images/icons/folder-minus.svg';
import FolderPlus from '../../static/images/icons/folder-plus.svg';
import Folder from '../../static/images/icons/folder.svg';
import Framer from '../../static/images/icons/framer.svg';
import Frown from '../../static/images/icons/frown.svg';
import Gift from '../../static/images/icons/gift.svg';
import GitBranch from '../../static/images/icons/git-branch.svg';
import GitCommit from '../../static/images/icons/git-commit.svg';
import GitMerge from '../../static/images/icons/git-merge.svg';
import GitPullRequest from '../../static/images/icons/git-pull-request.svg';
import Github from '../../static/images/icons/github.svg';
import Gitlab from '../../static/images/icons/gitlab.svg';
import Globe from '../../static/images/icons/globe.svg';
import Grid from '../../static/images/icons/grid.svg';
import HardDrive from '../../static/images/icons/hard-drive.svg';
import Hash from '../../static/images/icons/hash.svg';
import Headphones from '../../static/images/icons/headphones.svg';
import Heart from '../../static/images/icons/heart.svg';
import HelpCircle from '../../static/images/icons/help-circle.svg';
import Hexagon from '../../static/images/icons/hexagon.svg';
import Home from '../../static/images/icons/home.svg';
import Image from '../../static/images/icons/image.svg';
import Inbox from '../../static/images/icons/inbox.svg';
import Info from '../../static/images/icons/info.svg';
import Instagram from '../../static/images/icons/instagram.svg';
import Italic from '../../static/images/icons/italic.svg';
import Key from '../../static/images/icons/key.svg';
import Layers from '../../static/images/icons/layers.svg';
import Layout from '../../static/images/icons/layout.svg';
import LifeBuoy from '../../static/images/icons/life-buoy.svg';
import Link2 from '../../static/images/icons/link-2.svg';
import Link from '../../static/images/icons/link.svg';
import Linkedin from '../../static/images/icons/linkedin.svg';
import List from '../../static/images/icons/list.svg';
import Loader from '../../static/images/icons/loader.svg';
import Lock from '../../static/images/icons/lock.svg';
import LogIn from '../../static/images/icons/log-in.svg';
import LogOut from '../../static/images/icons/log-out.svg';
import Mail from '../../static/images/icons/mail.svg';
import MapPin from '../../static/images/icons/map-pin.svg';
import Map from '../../static/images/icons/map.svg';
import Maximize2 from '../../static/images/icons/maximize-2.svg';
import Maximize from '../../static/images/icons/maximize.svg';
import Meh from '../../static/images/icons/meh.svg';
import Menu from '../../static/images/icons/menu.svg';
import MessageCircle from '../../static/images/icons/message-circle.svg';
import MessageSquare from '../../static/images/icons/message-square.svg';
import MicOff from '../../static/images/icons/mic-off.svg';
import Mic from '../../static/images/icons/mic.svg';
import Minimize2 from '../../static/images/icons/minimize-2.svg';
import Minimize from '../../static/images/icons/minimize.svg';
import MinusCircle from '../../static/images/icons/minus-circle.svg';
import MinusSquare from '../../static/images/icons/minus-square.svg';
import Minus from '../../static/images/icons/minus.svg';
import Monitor from '../../static/images/icons/monitor.svg';
import Moon from '../../static/images/icons/moon.svg';
import MoreHorizontal from '../../static/images/icons/more-horizontal.svg';
import MoreVertical from '../../static/images/icons/more-vertical.svg';
import MousePointer from '../../static/images/icons/mouse-pointer.svg';
import Move from '../../static/images/icons/move.svg';
import Music from '../../static/images/icons/music.svg';
import Navigation2 from '../../static/images/icons/navigation-2.svg';
import Navigation from '../../static/images/icons/navigation.svg';
import Octagon from '../../static/images/icons/octagon.svg';
import Package from '../../static/images/icons/package.svg';
import Paperclip from '../../static/images/icons/paperclip.svg';
import PauseCircle from '../../static/images/icons/pause-circle.svg';
import Pause from '../../static/images/icons/pause.svg';
import PenTool from '../../static/images/icons/pen-tool.svg';
import Percent from '../../static/images/icons/percent.svg';
import PhoneCall from '../../static/images/icons/phone-call.svg';
import PhoneForwarded from '../../static/images/icons/phone-forwarded.svg';
import PhoneIncoming from '../../static/images/icons/phone-incoming.svg';
import PhoneMissed from '../../static/images/icons/phone-missed.svg';
import PhoneOff from '../../static/images/icons/phone-off.svg';
import PhoneOutgoing from '../../static/images/icons/phone-outgoing.svg';
import Phone from '../../static/images/icons/phone.svg';
import PieChart from '../../static/images/icons/pie-chart.svg';
import PlayCircle from '../../static/images/icons/play-circle.svg';
import Play from '../../static/images/icons/play.svg';
import PlusCircle from '../../static/images/icons/plus-circle.svg';
import PlusSquare from '../../static/images/icons/plus-square.svg';
import Plus from '../../static/images/icons/plus.svg';
import Pocket from '../../static/images/icons/pocket.svg';
import Power from '../../static/images/icons/power.svg';
import Printer from '../../static/images/icons/printer.svg';
import Radio from '../../static/images/icons/radio.svg';
import RefreshCcw from '../../static/images/icons/refresh-ccw.svg';
import RefreshCw from '../../static/images/icons/refresh-cw.svg';
import Repeat from '../../static/images/icons/repeat.svg';
import Rewind from '../../static/images/icons/rewind.svg';
import RotateCcw from '../../static/images/icons/rotate-ccw.svg';
import RotateCw from '../../static/images/icons/rotate-cw.svg';
import Rss from '../../static/images/icons/rss.svg';
import Save from '../../static/images/icons/save.svg';
import Scissors from '../../static/images/icons/scissors.svg';
import Search from '../../static/images/icons/search.svg';
import Send from '../../static/images/icons/send.svg';
import Server from '../../static/images/icons/server.svg';
import Settings from '../../static/images/icons/settings.svg';
import Share2 from '../../static/images/icons/share-2.svg';
import Share from '../../static/images/icons/share.svg';
import ShieldOff from '../../static/images/icons/shield-off.svg';
import Shield from '../../static/images/icons/shield.svg';
import ShoppingBag from '../../static/images/icons/shopping-bag.svg';
import ShoppingCart from '../../static/images/icons/shopping-cart.svg';
import Shuffle from '../../static/images/icons/shuffle.svg';
import Sidebar from '../../static/images/icons/sidebar.svg';
import SkipBack from '../../static/images/icons/skip-back.svg';
import SkipForward from '../../static/images/icons/skip-forward.svg';
import Slack from '../../static/images/icons/slack.svg';
import Slash from '../../static/images/icons/slash.svg';
import Sliders from '../../static/images/icons/sliders.svg';
import Smartphone from '../../static/images/icons/smartphone.svg';
import Smile from '../../static/images/icons/smile.svg';
import Speaker from '../../static/images/icons/speaker.svg';
import Square from '../../static/images/icons/square.svg';
import Star from '../../static/images/icons/star.svg';
import StopCircle from '../../static/images/icons/stop-circle.svg';
import Sun from '../../static/images/icons/sun.svg';
import Sunrise from '../../static/images/icons/sunrise.svg';
import Sunset from '../../static/images/icons/sunset.svg';
import Tablet from '../../static/images/icons/tablet.svg';
import Tag from '../../static/images/icons/tag.svg';
import Target from '../../static/images/icons/target.svg';
import Terminal from '../../static/images/icons/terminal.svg';
import Thermometer from '../../static/images/icons/thermometer.svg';
import ThumbsDown from '../../static/images/icons/thumbs-down.svg';
import ThumbsUp from '../../static/images/icons/thumbs-up.svg';
import ToggleLeft from '../../static/images/icons/toggle-left.svg';
import ToggleRight from '../../static/images/icons/toggle-right.svg';
import Tool from '../../static/images/icons/tool.svg';
import Trash2 from '../../static/images/icons/trash-2.svg';
import Trash from '../../static/images/icons/trash.svg';
import Trello from '../../static/images/icons/trello.svg';
import TrendingDown from '../../static/images/icons/trending-down.svg';
import TrendingUp from '../../static/images/icons/trending-up.svg';
import Triangle from '../../static/images/icons/triangle.svg';
import Truck from '../../static/images/icons/truck.svg';
import Tv from '../../static/images/icons/tv.svg';
import Twitch from '../../static/images/icons/twitch.svg';
import Twitter from '../../static/images/icons/twitter.svg';
import Type from '../../static/images/icons/type.svg';
import Umbrella from '../../static/images/icons/umbrella.svg';
import Underline from '../../static/images/icons/underline.svg';
import Unlock from '../../static/images/icons/unlock.svg';
import UploadCloud from '../../static/images/icons/upload-cloud.svg';
import Upload from '../../static/images/icons/upload.svg';
import UserCheck from '../../static/images/icons/user-check.svg';
import UserMinus from '../../static/images/icons/user-minus.svg';
import UserPlus from '../../static/images/icons/user-plus.svg';
import UserX from '../../static/images/icons/user-x.svg';
import User from '../../static/images/icons/user.svg';
import Users from '../../static/images/icons/users.svg';
import VideoOff from '../../static/images/icons/video-off.svg';
import Video from '../../static/images/icons/video.svg';
import Voicemail from '../../static/images/icons/voicemail.svg';
import Volume1 from '../../static/images/icons/volume-1.svg';
import Volume2 from '../../static/images/icons/volume-2.svg';
import VolumeX from '../../static/images/icons/volume-x.svg';
import Volume from '../../static/images/icons/volume.svg';
import Watch from '../../static/images/icons/watch.svg';
import WifiOff from '../../static/images/icons/wifi-off.svg';
import Wifi from '../../static/images/icons/wifi.svg';
import Wind from '../../static/images/icons/wind.svg';
import XCircle from '../../static/images/icons/x-circle.svg';
import XOctagon from '../../static/images/icons/x-octagon.svg';
import XSquare from '../../static/images/icons/x-square.svg';
import X from '../../static/images/icons/x.svg';
import Youtube from '../../static/images/icons/youtube.svg';
import ZapOff from '../../static/images/icons/zap-off.svg';
import Zap from '../../static/images/icons/zap.svg';
import ZoomIn from '../../static/images/icons/zoom-in.svg';
import ZoomOut from '../../static/images/icons/zoom-out.svg';

export {
  Activity,
  Airplay,
  AlertCircle,
  AlertOctagon,
  AlertTriangle,
  AlignCenter,
  AlignJustify,
  AlignLeft,
  AlignRight,
  Anchor,
  Aperture,
  Archive,
  ArrowDownCircle,
  ArrowDownLeft,
  ArrowDownRight,
  ArrowDown,
  ArrowLeftCircle,
  ArrowLeft,
  ArrowRightCircle,
  ArrowRight,
  ArrowUpCircle,
  ArrowUpLeft,
  ArrowUpRight,
  ArrowUp,
  AtSign,
  Award,
  BarChart2,
  BarChart,
  BatteryCharging,
  Battery,
  BellOff,
  Bell,
  Bluetooth,
  Bold,
  BookOpen,
  Book,
  Bookmark,
  Box,
  Briefcase,
  Calendar,
  CameraOff,
  Camera,
  Cast,
  CheckCircle,
  CheckSquare,
  Check,
  ChevronDown,
  ChevronLeft,
  ChevronRight,
  ChevronUp,
  ChevronsDown,
  ChevronsLeft,
  ChevronsRight,
  ChevronsUp,
  Chrome,
  Circle,
  Clipboard,
  Clock,
  CloudDrizzle,
  CloudLightning,
  CloudOff,
  CloudRain,
  CloudSnow,
  Cloud,
  Code,
  Codepen,
  Codesandbox,
  Coffee,
  Columns,
  Command,
  Compass,
  Copy,
  CornerDownLeft,
  CornerDownRight,
  CornerLeftDown,
  CornerLeftUp,
  CornerRightDown,
  CornerRightUp,
  CornerUpLeft,
  CornerUpRight,
  Cpu,
  CreditCard,
  Crop,
  Crosshair,
  Database,
  Delete,
  Disc,
  DollarSign,
  DownloadCloud,
  Download,
  Droplet,
  Edit2,
  Edit3,
  Edit,
  ExternalLink,
  EyeOff,
  Eye,
  Facebook,
  FastForward,
  Feather,
  Figma,
  FileMinus,
  FilePlus,
  FileText,
  File,
  Film,
  Filter,
  Flag,
  FolderMinus,
  FolderPlus,
  Folder,
  Framer,
  Frown,
  Gift,
  GitBranch,
  GitCommit,
  GitMerge,
  GitPullRequest,
  Github,
  Gitlab,
  Globe,
  Grid,
  HardDrive,
  Hash,
  Headphones,
  Heart,
  HelpCircle,
  Hexagon,
  Home,
  Image,
  Inbox,
  Info,
  Instagram,
  Italic,
  Key,
  Layers,
  Layout,
  LifeBuoy,
  Link2,
  Link,
  Linkedin,
  List,
  Loader,
  Lock,
  LogIn,
  LogOut,
  Mail,
  MapPin,
  Map,
  Maximize2,
  Maximize,
  Meh,
  Menu,
  MessageCircle,
  MessageSquare,
  MicOff,
  Mic,
  Minimize2,
  Minimize,
  MinusCircle,
  MinusSquare,
  Minus,
  Monitor,
  Moon,
  MoreHorizontal,
  MoreVertical,
  MousePointer,
  Move,
  Music,
  Navigation2,
  Navigation,
  Octagon,
  Package,
  Paperclip,
  PauseCircle,
  Pause,
  PenTool,
  Percent,
  PhoneCall,
  PhoneForwarded,
  PhoneIncoming,
  PhoneMissed,
  PhoneOff,
  PhoneOutgoing,
  Phone,
  PieChart,
  PlayCircle,
  Play,
  PlusCircle,
  PlusSquare,
  Plus,
  Pocket,
  Power,
  Printer,
  Radio,
  RefreshCcw,
  RefreshCw,
  Repeat,
  Rewind,
  RotateCcw,
  RotateCw,
  Rss,
  Save,
  Scissors,
  Search,
  Send,
  Server,
  Settings,
  Share2,
  Share,
  ShieldOff,
  Shield,
  ShoppingBag,
  ShoppingCart,
  Shuffle,
  Sidebar,
  SkipBack,
  SkipForward,
  Slack,
  Slash,
  Sliders,
  Smartphone,
  Smile,
  Speaker,
  Square,
  Star,
  StopCircle,
  Sun,
  Sunrise,
  Sunset,
  Tablet,
  Tag,
  Target,
  Terminal,
  Thermometer,
  ThumbsDown,
  ThumbsUp,
  ToggleLeft,
  ToggleRight,
  Tool,
  Trash2,
  Trash,
  Trello,
  TrendingDown,
  TrendingUp,
  Triangle,
  Truck,
  Tv,
  Twitch,
  Twitter,
  Type,
  Umbrella,
  Underline,
  Unlock,
  UploadCloud,
  Upload,
  UserCheck,
  UserMinus,
  UserPlus,
  UserX,
  User,
  Users,
  VideoOff,
  Video,
  Voicemail,
  Volume1,
  Volume2,
  VolumeX,
  Volume,
  Watch,
  WifiOff,
  Wifi,
  Wind,
  XCircle,
  XOctagon,
  XSquare,
  X,
  Youtube,
  ZapOff,
  Zap,
  ZoomIn,
  ZoomOut
}