import React, { Component } from 'react';
import { Link } from 'gatsby';
import styled, { css } from 'styled-components';
import AniLink from 'gatsby-plugin-transition-link/AniLink';

import * as Icons from './Icon';
import { Color, FontOsynlig } from '../../config/variables';

interface ButtonProps {
  icon?: string;
  onClick?: Function;
  size?: string;
  text: string;
  theme?: string;
  to?: string | object;
  href?: string;
  target?: string;
  className?: string;
  onMouseEnter?: Function;
  onMouseLeave?: Function;
  extraStyle?: any;
  inline?: boolean;
}

export default class Button extends Component<ButtonProps> {
  static defaultProps: ButtonProps = {
    theme: 'default',
    text: '',
    to: undefined,
    icon: undefined,
    size: undefined,
    target: undefined,
  };

  constructor(props: ButtonProps) {
    super(props);
  }

  handleClick() {}

  render() {
    const { icon, size, text, theme, target, to, href, onClick, className, onMouseEnter, onMouseLeave, inline, extraStyle } = this.props;

    const Icon = icon && Icons[icon];

    return to ? (
      <StyledLink
        className={className}
        to={to}
        size={size}
        theme={theme}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        style={extraStyle}
        bg={Color.citrus5}
        cover
        direction="left"
        inline={inline ? 1 : 0}
      >
        {text}
        {Icon && <Icon className="icon" viewBox="0 0 24 24" />}
      </StyledLink>
    ) : href ? (
      <StyledExternal
        className={className}
        href={href}
        target={target}
        rel={target && target === '_blank' ? 'noopener noreferrer' : null}
        size={size}
        theme={theme}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        style={extraStyle}
        inline={inline ? 1 : 0}
      >
        {text}
        {Icon && <Icon className="icon" viewBox="0 0 24 24" />}
      </StyledExternal>
    ) : (
      <StyledButton
        className={className}
        onClick={onClick}
        size={size}
        theme={theme}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        style={extraStyle}
        aria-label={text}
        inline={inline ? 1 : 0}
      >
        {text}
        {Icon && <Icon className="icon" viewBox="0 0 24 24" />}
      </StyledButton>
    );
  }
}

const SharedStyles = css`
  appearance: none;
  border: none;
  outline: none;
  ${FontOsynlig}
  font-size: ${props => (props.size === 'small' && props.theme === 'ghost' ? `.875em` : `${props.size === 'small' ? '.75em' : '1em'}`)};
  background-color: ${props => theme.default[props.theme]};
  color: ${props => (props.theme === 'ghost' ? 'currentColor' : Color.white)};
  stroke: ${props => (props.theme === 'ghost' ? Color.citrus : Color.white)};
  padding: ${props => (props.size === 'small' ? '.5em 2em' : '.75em 2em')};
  border-radius: 1.5rem;
  ${props =>
    props.icon &&
    `
    background-image: url('icons/${props.icon}.svg');
  `}
  line-height: ${props => (props.size === 'small' ? '1.7' : '1.5')};
  display: ${props => (props.inline ? 'inline-flex' : 'flex')};
  justify-content: center;
  align-items: center;
  ${props =>
    props.theme === 'ghost' &&
    `
    padding: 0;
    justify-content: flex-start;
  `}

  svg {
    transition: transform 350ms ease;
  }
  .hover &,
  &:hover, &:focus {
    background-color: ${props => theme.hover[props.theme]};
    ${props =>
      props.theme === 'ghost'
        ? `
      opacity: .8;

      svg {
        transform: translateX(.25em);
      }
    `
        : `
      color: ${Color.white80};
    `}
  }
  &:active {
    background-color: ${props => theme.active[props.theme]};
    ${props =>
      props.theme === 'ghost'
        ? `
      opacity: .8;
    `
        : `
      color: ${Color.white80}
    `}
  }
`;

const StyledButton = styled.button`
  ${SharedStyles};
`;

const StyledLink = styled(AniLink)`
  ${SharedStyles};
`;

const StyledExternal = styled.a`
  ${SharedStyles};
`;

const theme = {
  default: {
    default: Color.begonia,
    secondary: Color.slate,
    ghost: 'transparent',
  },
  hover: {
    default: Color.begonia2,
    secondary: Color.slate2,
    ghost: 'transparent',
  },
  active: {
    default: Color.slate,
    secondary: Color.slate,
    ghost: 'transparent',
  },
};
