import React, { Component } from 'react';
import styled from 'styled-components';
import Fade from 'react-reveal/Fade';

import { BlockContactHero as BlockContactHeroType } from '../shared/interfaces';
import { WrapLarge } from 'src/components/shared/wraps';
import { Markdown } from 'src/components/shared';
import { Color, ScalingSize } from '../config/variables';

interface Props {
  block: BlockContactHeroType;
}

export const BlockContactHero = (props: Props) => (
  <div>
    <WrapLarge>
      <Fade bottom distance={'10%'} fraction={0.2}>
        <Heading1>{props.block.Heading}</Heading1>
      </Fade>
    </WrapLarge>

    <Contacts data-color="#fff">
      <Fade bottom distance={'10%'} fraction={0.2}>
        <address>
          <Markdown content={props.block.Column1} />
        </address>
      </Fade>

      <Fade bottom distance={'10%'} fraction={0.2}>
        <address>
          <Markdown content={props.block.Column2} />
        </address>
      </Fade>

      <Fade bottom distance={'10%'} fraction={0.2}>
        <address>
          <Markdown content={props.block.Column3} />
        </address>
      </Fade>
    </Contacts>
  </div>
);

const Heading1 = styled.h1`
  color: ${Color.slate};
  ${ScalingSize('font-size', 40, 128, 1.430555556)};
  ${ScalingSize('margin-top', 40, 88)};
  ${ScalingSize('margin-bottom', 32, 72)}
  letter-spacing: -.03em;
  margin: 0 0 0.81em;
  line-height: 1.1;
`;

const Heading3 = styled.h3`
  color: ${Color.slate};
  ${ScalingSize('font-size', 16, 24)};
  line-height: 1.333333333;
  letter-spacing: -0.02em;
  margin: 0 0 1rem;
`;
const Paragraph = styled.p`
  line-height: 1.75;
  margin: 1em 0 2em;
  color: ${Color.slate3};
`;

const Contacts = styled(WrapLarge)`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 3.611111111vw 7.222222222vw;
  margin-bottom: 3.611111111vw;

  @media screen and (min-width: 978px) {
    grid-template-columns: repeat(3, 1fr);
  }
`;
