import React, { Component, createRef } from 'react';
import styled from 'styled-components';
import Fade from 'react-reveal/Fade';


import { WrapMax } from 'src/components/shared/wraps';
import { Button } from 'src/components/shared';
import { ScalingSize, Color } from 'src/config/variables';

import { BlockProcessHero as ProcessHeroType } from '../shared/interfaces';

interface Props {
  block: ProcessHeroType;
}

export const BlockProcessHero = (props: Props) => (
  <Outer data-color={Color.white}>
    <Inner>
      <Fade bottom distance={'10%'} fraction={0.2}>
        <Header1>{props.block.H1}</Header1>
      </Fade>
      <Fade bottom distance={'10%'} fraction={0.2}>
        <Header2>
          {props.block.H2} <Mark c={Color.citrus4}>{props.block.H2Highlight}</Mark> {props.block.H2AfterHighlight}
        </Header2>
      </Fade>
      <Fade bottom distance={'10%'} fraction={0.2}>
        <Lead>
          {props.block.Content}
        </Lead>
      </Fade>
      {props.block.Button && (
        <StyledFade bottom distance={'10%'} fraction={0.2} as={Button} href={props.block.Button.URL} text={props.block.Button.Title} icon="ArrowRight" />
      )}
    </Inner>
  </Outer>
);


const Header1 = styled.h1`
  margin: 0;
  ${ScalingSize('font-size', 40, 128, 1.430555556)};
  margin-bottom: 0.5rem;
  letter-spacing: -'.03em';
  line-height: 1.1;

  @media screen and (min-width: 860px) {
    text-align: center;
    grid-column: 1 / 2;
    margin-bottom: 0;
  }
`;

const Header2 = styled.h2`
  margin: 0;
  ${ScalingSize('font-size', 24, 48)}
  letter-spacing: -.02em;
  @media screen and (min-width: 860px) {
    text-align: center;
    grid-column: 1;
  }
`;

const Lead = styled.p`
  line-height: 1.75;
  margin: 1em 0 2em;
  color: ${Color.slate3};
  ${ScalingSize('font-size', 16, 20)}
  max-width: 40rem;

  @media screen and (min-width: 860px) {
    margin-left: auto;
    margin-right: auto;
  }

  @media screen and (min-width: 1440px) {
    max-width: 50rem;
  }
`;

const StyledFade = styled(Fade)`
  margin: 1em 0 0;
  justify-self: start;

  @media screen and (min-width: 860px) {
    justify-self: center;
    margin-top: 0;
    grid-column: 1 / 2;
  }
`;

const Outer = styled.section`
  ${ScalingSize('margin-top', 40, 88)};
  ${ScalingSize('margin-bottom', 32, 72)}
`;

const Inner = styled(WrapMax)`
  display: grid;
  grid-template-columns: 100%;
  grid-gap: 1em;

  @media screen and (min-width: 860px) {
    grid-gap: 1.75em;
  }
`;

const Mark = styled.mark`
  display: inline-block;
  background-color: transparent;
  position: relative;
  z-index: 1;
  color: currentColor;

  ${props => `
  &:before {
    background-image: url("data:image/svg+xml,%3Csvg width='134' height='26' viewBox='0 0 134 26' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg style='mix-blend-mode:multiply'%3E%3Cpath d='M8.40808 2.24112L133.103 1.25566L133.101 1.52921L98.5007 1.85092L122.532 1.73623L133.099 1.80276L132.374 3.166L60.5223 3.11637L132.191 3.27949L131.266 4.51936L118.709 4.71385L131.086 4.79178L130.523 5.95584L124.041 11.8565L121.786 17.3893L6.34826 17.4926L126.582 16.0758L125.838 17.4941L122.393 22.389L121.485 24.398L105.882 24.2924L89.4047 24.1886L60.3883 24.4037L46.2518 24.0608L26.8935 24.3504L15.1 24.0227L1.39176 23.9334L2.82849 20.802L4.21478 16.4996L40.0277 16.8302L1.02818 19.2136L7.06347 7.18505L14.9956 6.88327L7.07599 6.89391L8.40808 2.24112Z' fill='${encodeURIComponent(
      props.c
    )}'/%3E%3C/g%3E%3C/svg%3E%0A"); 
    overflow: visible;
    background-size: 100% auto;
    background-position: 50%;
    background-repeat: no-repeat;
    content: '';
    position: absolute;
    top: 0;
    right: -.125em;
    bottom: 0;
    left: -.125em;
    z-index: -1;
    transform: rotate(-2deg);
  }
  `}
`;