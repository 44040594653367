import React, { Component, createRef } from 'react';
import styled from 'styled-components';
import Fade from 'react-reveal/Fade';

import { BlockOfferLineup as BlockOfferLineupType } from '../shared/interfaces';
import { ProductCard } from 'src/components/shared';
import { Color, DynamicMargin } from '../config/variables';

interface Props {
  block: BlockOfferLineupType;
}

const viewport = createRef<HTMLBaseElement>();
const content = createRef<HTMLDivElement>();

export const BlockOfferLineup = (props: Props) => (
  <Container>
    <Title>{props.block.Heading}</Title>
    <Scroller ref={viewport}>
      <Content ref={content}>
        {props.block.Offers.map((offer, i) => (
          <ProductCard key={i} data={offer} />
        ))}
      </Content>
    </Scroller>
  </Container>
);

const Container = styled.section`
  display: grid;
  grid-auto-columns: max-content;
  margin: 0 auto;
  justify-content: center;
  ${DynamicMargin};
`;

const Title = styled.h2`
  display: grid;
  grid-gap: 1.5rem;
  grid-auto-flow: column;
  text-align: center;
`;

const Scroller = styled.div`
  white-space: nowrap;
  max-width: 100vw;
  margin-top: 2.5em;
  padding-bottom: 6em;
  overflow: scroll;

  &::-webkit-scrollbar {
    display: none;
    -webkit-appearance: none;
  }
`;

const Content = styled.div`
  display: grid;
  grid-gap: 1.5rem;
  grid-auto-flow: column;
  grid-template-columns: 1.5rem repeat(4, 1fr) 1.5rem;

  @media screen and (min-width: 768px) {
    grid-gap: 3vw;
  }

  &:before,
  &:after {
    content: '';
  }
`;