import React from 'react';
import { graphql } from 'gatsby';

import Layout from '../components/layout';
import SEO from '../components/seo';
import styled from 'styled-components';
import { Color, ScalingSize } from '../config/variables';
import { WrapMax, WrapXs } from '../components/shared/wraps';
import { Page, Button } from '../components/shared';
import Fade from 'react-reveal/Fade';

const NotFoundPage = () => {
  return (
    <Page>
      <SEO title="UX-designbyrå i Sundsvall, Stockholm och världen." />
      <WrapMax data-color={Color.white}>
        <Fade bottom distance={'10%'} fraction={0.2}>
          <Heading1>Sidan finns inte</Heading1>
        </Fade>
      </WrapMax>
      <WrapCenter>
        <Fade bottom distance={'10%'} fraction={0.2}>
          <Excerpt data-color={Color.white}>Sidan du sökte efter finns inte.</Excerpt>
        </Fade>
        <Fade bottom distance={'10%'} fraction={0.2}>
          <Button inline bg={Color.citrus5} cover direction="left" to="/" text="Till startsidan" icon="ArrowRight" />
        </Fade>
      </WrapCenter>
    </Page>
  );
};

export default NotFoundPage;

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`;

const Heading1 = styled.h1`
  color: ${Color.slate};
  ${ScalingSize('font-size', 40, 128, 1.430555556)};
  ${ScalingSize('margin-top', 40, 88)};
  ${ScalingSize('margin-bottom', 32, 72)}
  letter-spacing: -.03em;
  margin: 0 0 0.81em;
  padding-right: 7.222222222vw;
  padding-left: 7.222222222vw;
  line-height: 1.1;

  @media screen and (min-width: 796px) {
    text-align: center;
  }
`;

const Paragraph = styled.p`
  line-height: 1.75;
  margin: 1em 0 2em;
  color: ${Color.slate3};
`;

const Excerpt = styled(Paragraph)`
  font-size: 1.25em;
  line-height: 1.6;
`;

const WrapCenter = styled(WrapXs)`
  text-align: center;
  margin-bottom: 6em;
`;
