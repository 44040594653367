import React, { Component } from 'react';
import styled from 'styled-components';

import Image from '../Image';
import { Color, FontOsynlig, ScalingSize } from '../../config/variables';
import Fade from 'react-reveal/Fade';
interface Item {
  title: string;
  content: string | Element | Element[];
}

interface Props {
  items: Item[];
}

export default class List extends Component<Props> {
  constructor(props: Props) {
    super(props);
  }

  render() {
    const { items } = this.props;

    return (
      <OrderedList>
        {items.map((item, index) => (
          <Fade bottom distance={'10%'} fraction={0.2} key={`listItem_${index}`}>
            <ListItem>
              <div>
                <Heading3>{item.title}</Heading3>
                {item.content}
              </div>
            </ListItem>
          </Fade>
        ))}
      </OrderedList>
    );
  }
}

const OrderedList = styled.ol`
  list-style: none;
  max-width: 60em;
  counter-reset: list;
  margin-right: auto;
  margin-left: auto;
  padding: 0;
  ${ScalingSize('margin-top', 32, 80)}
  ${ScalingSize('margin-bottom', 32, 80)}
  font-size: 1em;
  line-height: 1.6;
  margin: 1em 0 2em;
  color: ${Color.slate3};
`;

const Heading3 = styled.h3`
  color: ${Color.slate2};
  ${ScalingSize('font-size', 16, 32)};
  line-height: 1.333333333;
  letter-spacing: -0.02em;
  margin: 0 0 1rem;
`;

const ListItem = styled.li`
  display: grid;
  grid-template-columns: 100%;
  grid-gap: 1em 5em;
  align-items: start;
  ${ScalingSize('margin-bottom', 16, 40)};

  @media screen and (min-width: 680px) {
    grid-template-columns: 13.888888889vw auto;
  }

  &:before {
    counter-increment: list;
    content: counters(list, '.', decimal-leading-zero);
    color: ${Color.cloud};
    ${FontOsynlig};
    ${ScalingSize('font-size', 40, 128, 1.430555556)};
    border-bottom: 0.375rem solid ${Color.citrus};
    width: fit-content;
    line-height: 1;
    padding-bottom: 0.7rem;

    @media screen and (min-width: 680px) {
      margin-bottom: 1.5rem;
      text-align: right;
      margin-left: auto;
    }
  }

  div,
  p {
    font-size: 1em;
    line-height: 1.6;
    margin: 1em 0 2em;
    color: ${Color.slate3};
  }

  a {
    color: ${Color.slate};
    border-bottom: 1px solid ${Color.citrus};
    transition: border 0.3s ease;

    &:hover {
      border-bottom: 1px solid ${Color.slate};
    }
  }
`;
