import React, { Component, createRef } from 'react';
import styled from 'styled-components';
import Fade from 'react-reveal/Fade';
import lottie from 'lottie-web';
import { textCleanup } from 'src/shared/utils';
import { WrapMax } from 'src/components/shared/wraps';
import { Color, FontOsynlig, FontSans, ScalingSize, ElevationSoft } from '../config/variables';
import { scroller } from 'src/lottie-files';
import parser from 'html-react-parser';

import { CaseHero as CaseHeroType } from 'src/components/shared/interfaces';

interface Props {
  block: CaseHeroType;
}
export class BlockCaseHero extends Component<Props> {
  private scrollerContainer = createRef<HTMLDivElement>();
  private animation: any;

  constructor(props: Props) {
    super(props);
  }

  componentDidMount() {
    this.animation = lottie.loadAnimation({
      container: this.scrollerContainer.current,
      renderer: 'svg',
      loop: true,
      autoplay: true,
      animationData: scroller,
    });
  }

  componentWillUnmount() {
    this.animation.destroy();
  }

  render() {
    const { block } = this.props;

    return (
      <div>
        <Fade bottom distance={'10%'} fraction={0.2}>
          <WrapMax>
            <Heading1>
              {textCleanup(block.H1)} <Mark c={Color.citrus4}>{textCleanup(block.H1Highlight)}</Mark> {textCleanup(block.H1AfterHighlight)}
            </Heading1>
          </WrapMax>
        </Fade>
        <ExcerptWrap>
          <Scroller>
            <ScrollerInner ref={this.scrollerContainer} />
          </Scroller>
          <Title>{block.H2}</Title>
          <Content>
            {parser(`
              <p>${block.ContentColumn1}</p>
              <p>${block.ContentColumn2}</p>
            `)}
          </Content>
          <Tags>
            {block.Tags.map((tag, i) => (
              <Tag key={i}>{tag.Tag}</Tag>
            ))}
          </Tags>
        </ExcerptWrap>
      </div>
    );
  }
}

const Heading1 = styled.h1`
  color: ${Color.slate};
  ${ScalingSize('font-size', 40, 128, 1.430555556)};
  ${ScalingSize('margin-top', 40, 88)};
  ${ScalingSize('margin-bottom', 32, 72)};
  ${ScalingSize('width', 280, 880)};
  ${ScalingSize('margin-right', 32, 103)};
  letter-spacing: -0.03em;
  line-height: 1.1;
`;

const Mark = styled.mark`
  display: inline-block;
  background-color: transparent;
  position: relative;
  z-index: 1;
  color: currentColor;

  ${props => `
  &:before {
    background-image: url("data:image/svg+xml,%3Csvg width='134' height='26' viewBox='0 0 134 26' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg style='mix-blend-mode:multiply'%3E%3Cpath d='M8.40808 2.24112L133.103 1.25566L133.101 1.52921L98.5007 1.85092L122.532 1.73623L133.099 1.80276L132.374 3.166L60.5223 3.11637L132.191 3.27949L131.266 4.51936L118.709 4.71385L131.086 4.79178L130.523 5.95584L124.041 11.8565L121.786 17.3893L6.34826 17.4926L126.582 16.0758L125.838 17.4941L122.393 22.389L121.485 24.398L105.882 24.2924L89.4047 24.1886L60.3883 24.4037L46.2518 24.0608L26.8935 24.3504L15.1 24.0227L1.39176 23.9334L2.82849 20.802L4.21478 16.4996L40.0277 16.8302L1.02818 19.2136L7.06347 7.18505L14.9956 6.88327L7.07599 6.89391L8.40808 2.24112Z' fill='${encodeURIComponent(
      props.c
    )}'/%3E%3C/g%3E%3C/svg%3E%0A"); 
    overflow: visible;
    background-size: 100% auto;
    background-position: 50%;
    background-repeat: no-repeat;
    content: '';
    position: absolute;
    top: 0;
    right: -.125em;
    bottom: 0;
    left: -.125em;
    z-index: -1;
    transform: rotate(-2deg);
  }
  `}
`;

const ExcerptWrap = styled(WrapMax)`
  display: grid;
  grid-gap: 1em 3.5em;
  ${ScalingSize('margin-top', 16, 144)}
  ${ScalingSize('margin-bottom', 32, 112)}
  
  @media screen and (min-width: 680px) {
    grid-template-columns: auto 8em;
  }

  @media screen and (min-width: 769px) {
    grid-template-columns: 8em auto 8em;
  }
`;

const Scroller = styled.div`
  position: relative;
  grid-column: 1;
  grid-row: 1 / 3;
  display: none;
  align-self: center;
  padding-bottom: 100%;
  border-radius: 99em;
  background-color: ${Color.white};
  ${ElevationSoft};

  @media screen and (min-width: 769px) {
    display: block;
  }
`;

const ScrollerInner = styled.div`
  position: absolute;
  width: 2em;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
`;

const Content = styled.div`
  @media screen and (min-width: 680px) {
    column-gap: 1.5em;
    grid-row: 2;
    columns: 2 366px;
    grid-column: 1;
    margin-right: 2.5em;
  }

  @media screen and (min-width: 769px) {
    grid-column: 2;
  }

  p {
    margin: 0 0 1.5em;
    line-height: 1.75;
    color: ${Color.slate3};
  }
`;

const Title = styled.span`
  display: flex;
  ${FontOsynlig};
  font-size: 1.5em;
  line-height: 2rem;
  position: relative;

  @media screen and (min-width: 680px) {
    grid-row: 1;
    grid-column: 1 / 3;
  }

  @media screen and (min-width: 769px) {
    grid-column: 2 / 4;
  }

  &:after {
    grid-row: 2;
    margin-left: auto;
    color: ${Color.citrus};
    content: '—';
    display: none;

    @media screen and (min-width: 680px) {
      display: block;
    }
  }
`;

const Tags = styled.ul`
  grid-row: 1;
  list-style: none;
  margin: 0;
  padding: 0;

  @media screen and (min-width: 680px) {
    grid-row: 2;
    text-align: right;
  }

  &:before {
    ${FontOsynlig};
    font-size: 1.5em;
    line-height: 2rem;
    color: ${Color.citrus};
    content: '—';
    display: block;
    margin-bottom: 0.5rem;

    @media screen and (min-width: 680px) {
      display: none;
    }
  }
`;

const Tag = styled.li`
  font-size: 0.6875em;
  line-height: 2em;
  ${FontSans}
  margin-bottom: .75rem;
  text-transform: uppercase;
  font-weight: bold;
  display: inline-flex;

  @media screen and (min-width: 680px) {
    display: block;
  }

  &:after {
    content: '·';
    margin: 0 0.75em;

    @media screen and (min-width: 680px) {
      display: none;
    }
  }

  &:last-child:after {
    display: none;
  }
`;
