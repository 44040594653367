import React, { Component } from 'react';
import styled from 'styled-components';
import Fade from 'react-reveal/Fade';
import { textCleanup } from 'src/shared/utils';
import { BlockMainHeading as BlockMainHeadingType } from '../shared/interfaces';
import { WrapMax } from 'src/components/shared/wraps';
import { Color, ScalingSize } from '../config/variables';

interface Props {
  block: BlockMainHeadingType;
}

export const BlockMainHeading = (props: Props) => (
  <WrapMax>
    <Fade bottom distance={'10%'} fraction={0.2}>
      <Heading1>{textCleanup(props.block.Text)}</Heading1>
    </Fade>
  </WrapMax>
);

const Heading1 = styled.h1`
  color: ${Color.slate};
  ${ScalingSize('font-size', 40, 128, 1.430555556)};
  ${ScalingSize('margin-top', 40, 88)};
  ${ScalingSize('margin-bottom', 32, 72)}
  letter-spacing: -.03em;
  margin: 0 0 0.81em;
  line-height: 1.1;
  text-align: center;
  hyphens: auto;
`;
