import React, { Component, createRef } from 'react';
import styled from 'styled-components';
import Fade from 'react-reveal/Fade';

import { StartPageHero as StartPageHeroType } from '../shared/interfaces';

import { WrapMax } from 'src/components/shared/wraps';
import { Button } from 'src/components/shared';
import { ScalingSize, Color } from '../config/variables';

interface Props {
  block: StartPageHeroType;
}

export const BlockStartPageHero = (props: Props) => (
  <Outer
    data-color={Color.champ5}
    data-circle
    data-circle-color={Color.citrus}
    data-circle-position-x={102.057291667}
    data-circle-position-y={137.8240741}
    data-circle-radius={27.2135416}
  >
    <Inner>
      <Header1>
        {props.block.H1}
      </Header1>
      <Row>
        <Header2>{props.block.H2}</Header2>
        <Excerpt>
          {props.block.Content}
        </Excerpt>
      </Row>
      <Cta bg={Color.citrus5} cover direction="left" as={Button} inline to={props.block.Button.URL} text={props.block.Button.Title} icon="ArrowRight" />
    </Inner>
  </Outer>
);

const Row = styled.div`
  display: grid;
  grid-template-columns: 100%;
  grid-gap: 1em;
  max-width: 61.625em;
  justify-self: center;
  align-items: center;

  @media screen and (min-width: 860px) {
    grid-gap: 4em 2.5em;
    grid-row: 2;
    grid-template-columns: 410fr 536fr;
  }
`;

const Header1 = styled.h1`
  margin: 0;
  ${ScalingSize('font-size', 40, 128, 1.430555556)};
  margin-bottom: 1.5rem;
  letter-spacing: -'.03em';
  line-height: 1.1;

  @media screen and (min-width: 860px) {
    text-align: center;
    grid-column: 1 / 2;
    margin-bottom: 0;
    margin-right: auto;
    margin-left: auto;
    max-width: 80%;
  }
`;

const Header2 = styled.h2`
  margin: 0;
  ${ScalingSize('font-size', 20, 32)}
  letter-spacing: -.02em;
  @media screen and (min-width: 860px) {
    text-align: right;
    grid-column: 1;
  }
`;

const Excerpt = styled.p`
  margin: 0;
  color: ${Color.slate3};
  line-height: 1.75;
  @media screen and (min-width: 860px) {
    grid-column: 2;
  }
`;

const Cta = styled.div`
  margin: 1em 0 0;
  justify-self: start;

  @media screen and (min-width: 860px) {
    justify-self: center;
    margin-top: 0;
    grid-column: 1 / 2;
  }
`;

const Outer = styled.section`
  ${ScalingSize('margin-top', 40, 88)};
  ${ScalingSize('margin-bottom', 32, 72)}
`;

const Inner = styled(WrapMax)`
  display: grid;
  grid-template-columns: 100%;
  grid-gap: 1em;

  @media screen and (min-width: 860px) {
    grid-gap: 4em;
  }
`;