import React, { Component } from 'react';
import styled from 'styled-components';
import AniLink from 'gatsby-plugin-transition-link/AniLink';
import { Button } from './index';
import { Color, ElevationSoft, ScalingSize } from '../../config/variables';

import Image from 'src/components/Image';

interface ProductImage {
  src: string;
  alt: string;
  imageFile: any;
}

interface Product {
  Heading: string;
  Content: string;
  Image: ProductImage;
  URL: string;
  LinkTitle: string;
}

interface Props {
  data: Product;
}

export default class ProductCard extends Component<Props> {
  constructor(props: Props) {
    super(props);
  }

  render() {
    const { data } = this.props;

    return (
      <Card>
        <AniLink to={data.URL} bg={Color.citrus5} cover direction="left">
        {data.Image && (
          <Figure>
            <StyledImage filename={data.Image.imageFile.relativePath} alt={data.Image.alternativeText} />
          </Figure>
        )}
        <Content>
          <Title>{data.Heading}</Title>
          <p>{data.Content}</p>
          <Button theme="ghost" size="small" text={data.LinkTitle} icon="ArrowRight" />
        </Content>
        </AniLink>
      </Card>
      
    );
  }
}

const Title = styled.h3`
  color: ${Color.slate};
  line-height: 2rem;
  margin: 0;
`;

const Card = styled.div`
  background-color: ${Color.white};
  border-radius: 1rem;
  overflow: hidden;
  ${ElevationSoft};
  width: 18.125em;
  display: grid;
  grid-template-rows: max-content 1fr;
  .hover &,
  &:hover, &:focus {
    svg {
      transform: translateX(.25em);
    }
  }
`;

const Figure = styled.figure`
  margin: 0;
  width: 100%;
  height: 14.5em;
  grid-row: 1;

  .gatsby-image-wrapper {
    width: 100%;
    height: 100%;

    img {
      object-fit: cover;
    }
  }
`;

const StyledImage = styled(Image)`
  width: 100%;
  height: 100%;
`;

const Content = styled.div`
  display: grid;
  grid-template-rows: 2rem 1fr 1.25rem;
  width: 100%;
  padding: 1.5em 2rem 2rem 2rem;
  white-space: normal;
  color: ${Color.slate3};
  grid-row: 2;

  p {
    font-size: 0.875em;
    margin-top: 0.75rem;
    line-height: 1.5rem;
  }
`;
