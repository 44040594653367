import React, { createElement } from 'react';
import styled from 'styled-components';
import ReactMarkdown from 'react-markdown';
import { textCleanup } from 'src/shared/utils';
import { Color, ScalingSize } from 'src/config/variables';

const Markdown = ({ content, indented }) => (
  <Wrapper indented={indented}>
    <ReactMarkdown
      source={textCleanup(content)}
      renderers={{
        paragraph: props => <Paragraph {...props} />,
        blockquote: props => <Blockquote {...props} />,
        link: props => <Link {...props} />,
        heading: Heading,
        list: props => <List {...props} />,
      }}
    />
  </Wrapper>
);

// Availiable renderers
// https://github.com/rexxars/react-markdown/blob/master/src/renderers.js

const Wrapper = styled.div`
  ${({ indented }) =>
    indented &&
    `
    grid-column: 1 / 32;
    margin-top: 1rem;
    width: 100%;

    @media screen and (min-width: 978px) {
      width: 85%;
      grid-column: 3 / 16;
    }
  `}
`;

const List = styled.ul`
  line-height: 1.6;
  margin: 1em 0 2em;
  color: ${Color.slate3};
  padding-left: 1em;

  li {
    ${ScalingSize('margin-bottom', 8, 16)}
  }
`;

const Link = styled.a`
  color: ${Color.slate};
  border-bottom: 1px solid ${Color.citrus};
  transition: border 0.2s ease;

  &:hover {
    border-bottom: 1px solid ${Color.slate};
  }
`;

const Blockquote = styled.blockquote`
  line-height: 1.75;
  margin: 1em 0 2em;
  padding-left: 1.25em;
  color: ${Color.iris};
  border-left: 3px solid ${Color.citrus4};

  p {
    color: ${Color.iris};
  }

  @media screen and (min-width: 769px) {
    padding-left: 2em;
  }
`;

const Paragraph = styled.p`
  line-height: 1.75;
  margin: 1em 0 2em;
  color: ${Color.slate3};
`;

const Excerpt = styled(Paragraph)`
  font-size: 1.25em;
  line-height: 1.6;
`;

const Heading1 = styled.h1`
  color: ${Color.slate};
  ${ScalingSize('font-size', 40, 128, 1.430555556)};
  ${ScalingSize('margin-top', 40, 88)};
  ${ScalingSize('margin-bottom', 32, 72)}
  letter-spacing: -.03em;
  margin: 0 0 0.81em;
  line-height: 1.1;

  @media screen and (min-width: 796px) {
    text-align: center;
  }
`;

const Heading2 = styled.h2`
  color: ${Color.slate};
  ${ScalingSize('font-size', 24, 48)};
  line-height: 1.25;
  letter-spacing: -0.02em;
`;

const Heading3 = styled.h3`
  color: ${Color.slate};
  ${ScalingSize('font-size', 16, 24)};
  line-height: 1.333333333;
  letter-spacing: -0.02em;
  margin: 2rem 0 1rem;
`;

const Heading = props => {
  switch (props.level) {
    case 1:
      return <Heading1 {...getCoreProps(props)}>{props.children}</Heading1>;
    case 2:
      return <Heading2 {...getCoreProps(props)}>{props.children}</Heading2>;
    case 3:
      return <Heading3 {...getCoreProps(props)}>{props.children}</Heading3>;
    case 5:
      return <Excerpt {...getCoreProps(props)}>{props.children}</Excerpt>;
    default:
      return createElement(`h${props.level}`, getCoreProps(props), props.children);
  }
};

const getCoreProps = props => {
  return props['data-sourcepos'] ? { 'data-sourcepos': props['data-sourcepos'] } : {};
};

export default Markdown;
